import React from "react";

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="App-footer">
            <div className="footer-content">
                <p>
                    &copy; {currentYear} Color-Converter. Natsu16 All rights
                    reserved.
                </p>
                <p>
                    PANTONE® and other Pantone trademarks are the property of
                    Pantone LLC. Pantone LLC is a wholly owned subsidiary of
                    X-Rite, Incorporated. © Pantone LLC, {currentYear}. All
                    rights reserved.
                </p>
            </div>
        </footer>
    );
};

export default Footer;
