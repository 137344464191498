import React, { useState, useEffect, useCallback, useMemo } from "react";
import colorData from "../colors_table.json";

const ColorConverter = () => {
    const [input, setInput] = useState("");
    const [result, setResult] = useState(null);

    const hexToRgb = useCallback((hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }, []);

    const rgbToHex = useCallback((r, g, b) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    }, []);

    const getContrastColor = useCallback((hexColor) => {
        const rgb = hexToRgb(hexColor);
        const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
        return brightness > 128 ? "black" : "white";
    }, [hexToRgb]);

    const convertColor = useCallback((value) => {
        const trimmedValue = value.trim().toUpperCase();

        // Pantone検索（簡略化された入力にも対応）
        const pantoneMatch = colorData.find(
            (color) =>
                color.pantone
                    .replace("PANTONE ", "")
                    .replace(/ C$/, "")
                    .replace(/ U$/, "") === trimmedValue ||
                color.pantone === `PANTONE ${trimmedValue}` ||
                color.pantone === `PANTONE ${trimmedValue} C` ||
                color.pantone === `PANTONE ${trimmedValue} U`
        );
        if (pantoneMatch) {
            setResult(pantoneMatch);
            return;
        }

        // HEX検索
        const hexMatch = colorData.find(
            (color) =>
                color.hex.toUpperCase() === `#${trimmedValue}` ||
                color.hex.toUpperCase() === trimmedValue
        );
        if (hexMatch) {
            setResult(hexMatch);
            return;
        }

        // RGB検索
        const rgbMatch = colorData.find((color) => {
            const inputRgb = trimmedValue.split(",").map((v) => v.trim());
            return (
                inputRgb.length === 3 &&
                color.rgb.r === inputRgb[0] &&
                color.rgb.g === inputRgb[1] &&
                color.rgb.b === inputRgb[2]
            );
        });
        if (rgbMatch) {
            setResult(rgbMatch);
            return;
        }

        // Pantoneマッチがない場合、HEXまたはRGBで直接変換
        if (trimmedValue.startsWith("#") || /^[0-9A-F]{6}$/.test(trimmedValue)) {
            const hex = trimmedValue.startsWith("#") ? trimmedValue : `#${trimmedValue}`;
            const rgb = hexToRgb(hex);
            setResult({
                pantone: "N/A",
                hex: hex,
                rgb: rgb
            });
            return;
        }

        const rgbValues = trimmedValue.split(",").map(v => parseInt(v.trim()));
        if (rgbValues.length === 3 && rgbValues.every(v => v >= 0 && v <= 255)) {
            const hex = rgbToHex(rgbValues[0], rgbValues[1], rgbValues[2]);
            setResult({
                pantone: "N/A",
                hex: hex,
                rgb: { r: rgbValues[0], g: rgbValues[1], b: rgbValues[2] }
            });
            return;
        }

        setResult(null);
    }, [hexToRgb, rgbToHex]);

    useEffect(() => {
        convertColor(input);
    }, [input, convertColor]);

    useEffect(() => {
        if (result) {
            document.body.style.backgroundColor = result.hex;
            document.body.style.color = getContrastColor(result.hex);
        } else {
            document.body.style.backgroundColor = "#f0f0f0";
            document.body.style.color = "black";
        }
    }, [result, getContrastColor]);

    const styles = useMemo(() => ({
        container: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            padding: "20px",
            boxSizing: "border-box",
        },
        card: {
            width: "100%",
            maxWidth: "400px",
            padding: "30px",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            borderRadius: "12px",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)",
        },
        inputContainer: {
            marginBottom: "24px",
        },
        input: {
            width: "100%",
            padding: "12px",
            fontSize: "16px",
            textAlign: "center",
            backgroundColor: "transparent",
            border: "none",
            borderBottom: "2px solid #ccc",
            outline: "none",
            transition: "border-color 0.3s",
        },
        result: {
            padding: "20px",
            borderRadius: "8px",
            textAlign: "center",
            transition: "background-color 0.3s, color 0.3s",
            minHeight: "120px",
            backgroundColor: result ? result.hex : "transparent",
            color: result ? getContrastColor(result.hex) : "inherit",
        },
    }), [result, getContrastColor]);

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <div style={styles.inputContainer}>
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Pantone, HEX, RGBを入力して下さい"
                        style={styles.input}
                    />
                </div>
                <div style={styles.result}>
                    {result ? (
                        <>
                            <p style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "12px" }}>
                                {result.pantone !== "N/A" ? result.pantone : "カスタムカラー"}
                            </p>
                            <p style={{ fontSize: "18px", marginBottom: "8px" }}>
                                HEX: {result.hex}
                            </p>
                            <p style={{ fontSize: "18px" }}>
                                RGB: {result.rgb.r}, {result.rgb.g}, {result.rgb.b}
                            </p>
                        </>
                    ) : (
                        <p style={{ fontSize: "18px" }}>結果はこちら</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ColorConverter;