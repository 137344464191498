import React from "react";
import logo from "../logo.svg";

const Header = () => {
    return (
        <header className="App-header">
            <div className="header-content">
                <div className="logo-container">
                    <img src={logo} className="App-logo" alt="logo" />
                </div>
                <div className="title-container">
                    <h1>Color Converter - Pantone, HEX, RGB</h1>
                    <p>対応するカラーコードを表示するアプリケーション</p>
                </div>
            </div>
        </header>
    );
};

export default Header;
