import "./App.css";
import Header from "./components/header";
import Footer from "./components/footer";
import ColorConverter from "./components/colorConverter";

function App() {
    return (
        <div className="App">
            <Header />
            <main className="App-main">
                <ColorConverter />
            </main>
            <Footer />
        </div>
    );
}

export default App;
